import React, { useState } from 'react';
import { PieChart, Pie, Cell, Label } from 'recharts';
import "./ChartComponent.css";
import { Modal } from 'react-bootstrap';

function ChartComponent(props) {
    const [showModal, setShowModal] = useState(false);
    const [recIndex, setRecIndex] = useState(0);
    const rating = [0, 1, 2, 3, 4];
    const emptyData = []
    for (let i = 0; i < props.pieNumber; i++) {
        emptyData.push({ "name": "q" + (i + 1).toString(), "value": 1 })
    }

    if (props.type === "target") { // target chart comprises of background empty chart (that accounts for target level), with circle for target level 
        return (
            <div>
                <div id="target-report-chart">
                    <PieChart width={500} height={500}>
                        {rating.map((ratingItem, ratingIndex) => { // this is supposed to map out 8 segments
                            return (
                                <Pie key={ratingIndex} data={emptyData} nameKey="name" dataKey="value" cx="50%" cy="50%"
                                    fill="#FFFFFF"
                                    innerRadius={(ratingIndex >= 5 - props.targetLevel) ? (ratingIndex * 44 + 7) : (ratingIndex * 44)}
                                    outerRadius={(ratingIndex >= 5 - props.targetLevel) ? ((ratingIndex + 1) * 44 + 7) : ((ratingIndex + 1) * 44)}
                                    isAnimationActive={false} startAngle={90} endAngle={-270}
                                    stroke="#E5E5E5" strokeWidth="1" />
                            )
                        })}
                    </PieChart>
                </div>
                <div id="target-target-level">
                    <svg width={500} height={500}>
                        <circle cx="50%" cy="50%" r={(props.targetLevel === 5) ? 1 : (5 - props.targetLevel) * 44} stroke="#009FDA" strokeWidth="12" fill="none" />
                    </svg>
                </div>
            </div>
        )
    }

    else if (props.type === "capability") { // capability chart comprises of background empty chart (that does not account for target level), and active segment
        return (
            <div>
                {props.data && <div>
                    <div id="capability-whole-chart">
                        <PieChart width={500} height={500}>
                            {rating.map((rating, ratingIndex) => { 
                                return (<Pie data={emptyData} key={ratingIndex} dataKey="value" nameKey="name" cx="50%" cy="50%"
                                    innerRadius={ratingIndex * 44} outerRadius={(ratingIndex + 1) * 44}
                                    startAngle={90} endAngle={-270}
                                    fill="#FFFFFF" stroke="#E5E5E5" isAnimationActive={false} />)
                            })}
                        </PieChart>
                    </div>

                    {props.data.questions && <div id="capability-active-segment"> 
                        <PieChart key={props.currentQnNumber} width={500} height={500}>
                            {props.data.questions[0].options.map((item, index) => { // this is supposed to map out 8 segments
                                return (<Pie key={item.text} data={[item]} dataKey="choice" nameKey="text" cx="50%" cy="50%" innerRadius={index * 44} outerRadius={(index + 1) * 44}
                                    startAngle={((props.pieNumber - (props.currentQnNumber - 1)) * 360 / props.pieNumber) - 270} endAngle={((props.pieNumber - props.currentQnNumber) * 360 / props.pieNumber) - 270}
                                    fill={sessionStorage.getItem('q' + props.currentQnNumber.toString()) === (5 - index).toString() ? "#009FDA" : "#FFFFFF"} stroke="#000000" isAnimationActive={false} />
                                )
                            })}
                        </PieChart>
                    </div>}
                </div>}
            </div>
        )
    }

    else if (props.type === "report") {
        // report chart consists of 4 components
        // at the bottom is the legend
        // above it is the highlighted background chart (that accounts for target level)
        // on top of it is the target level, the images that display the level definitions, and the recommendation items
        const width = 1000;
        const height = 700;

        // modal/popup functions 
        const handleClose = () => setShowModal(false);
        const handleShow = () => setShowModal(true);

        // make a json that has empty data for display purposes for the target reached portion
        const emptyTargetData = [{ "name": "name", "value": 1 }]

        // make a json that has the capability info and value = 1 for pie display purposes
        const recData = props.data.capability
        for (let i = 0; i < props.pieNumber; i++) {
            recData[i].value = 1
        }

        // this is to determine which image to render for which level
        const levelDefData = [{ "name": "nameval", "value": 1 }];
        const d = { 1: "optimized", 2: "managed", 3: "defined", 4: "repeatable", 5: "initial" }

        // this calculates the coordinates for the recommendation items
        const RADIAN = Math.PI / 180;
        const renderCustomizedLabel = ({
            cx, cy, midAngle, innerRadius, outerRadius, percent, index,
        }) => {
            const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
            const x = cx + radius * Math.cos(-midAngle * RADIAN);
            const y = cy + radius * Math.sin(-midAngle * RADIAN);
            return (
                <g>
                    <foreignObject
                        x={(Math.ceil((props.pieNumber) / 2) - 1 === index && (props.pieNumber) % 2 === 1) ? x - 100 : ((x > cx) ? x - 15 : x - 210)}
                        y={(Math.ceil((props.pieNumber) / 2) - 1 === index && (props.pieNumber) % 2 === 1) ? y - 50 : (index === 0 || index === props.pieNumber - 1) ? y - 110 : y - 75}
                        width="200" height="200" className="rec-obj">
                        <h3>
                            {recData[index].title}
                        </h3>
                        {recData[index].recommendation !== "" && <button className="next-level" onClick={() => { howToReachNextLvl(index) }}>
                            {props.pageText["button.gap"]}
                        </button>}
                    </foreignObject>
                </g>
            );
        };

        // onClick 'how to get to the next level'
        function howToReachNextLvl(index) {
            setRecIndex(index);
            handleShow();
        }

        return (
            <div>
                {props.data && <div className="overall">
                    <div id="report-report-chart"> {/* base empty n questions * 5 chart */}
                        <PieChart width={width} height={height}>
                            {rating.map((ratingItem, ratingIndex) => {
                                return (
                                    <Pie key={ratingIndex} data={emptyData} dataKey="value" nameKey="name" cx="50%" cy="50%"
                                        innerRadius={(ratingIndex >= 6 - props.targetLevel) ? ((ratingIndex * 44) + 14) :
                                            (ratingIndex >= 5 - props.targetLevel) ? ((ratingIndex * 44) + 7) :
                                                (ratingIndex * 44)}
                                        outerRadius={(ratingIndex >= 6 - props.targetLevel) ? ((ratingIndex === 4) ? ((ratingIndex + 1) * 44 + 7) : ((ratingIndex + 1) * 44 + 14)) :
                                            (ratingIndex >= 5 - props.targetLevel) ? ((ratingIndex + 1) * 44 + 7) :
                                                ((ratingIndex + 1) * 44)}
                                        fill="#FFFFFF" isAnimationActive={false} startAngle={90} endAngle={-270} stroke="#E5E5E5" strokeWidth="1">
                                        {props.data.capability.map((cellItem, cellIndex) => (
                                            <Cell key={cellIndex + cellItem.piePosition}
                                                fill={(cellItem.levelChoice >= props.targetLevel) ?
                                                    ((cellItem.levelChoice > 4 - ratingIndex) ? "#36842D" : "#FFFFFF") :
                                                    ((cellItem.levelChoice > 4 - ratingIndex) ? "#36842D" : "#FFFFFF")
                                                } />
                                        ))}
                                    </Pie>
                                )
                            })}
                        </PieChart>
                    </div>
                    <div id="report-target-level"> {/* blue cirles */}
                        {props.targetLevel && <svg width={width} height={height}>
                            <circle cx="50%" cy="50%" r={(props.targetLevel === 5) ? 5 : ((5 - props.targetLevel) * 44)} stroke="#009FDA" strokeWidth="12" fill="none" />
                            <circle cx="50%" cy="50%" r={(6 - props.targetLevel) * 44 + 10} stroke="#009FDA" strokeWidth="5" fill="none" />
                        </svg>}
                    </div>
                    <div id="report-def-level"> {/* level def images */}
                        <PieChart width={width} height={height}>
                            {rating.map((ratingItem, ratingIndex) => { // this is supposed to map out 8 segments
                                return (
                                    <Pie key={ratingIndex} data={levelDefData} dataKey="value" nameKey="name" cx="50%" cy="50%"
                                        innerRadius={(ratingIndex >= 5 - props.targetLevel) ? (ratingIndex * 44 + 7) : (ratingIndex * 44)}
                                        outerRadius={(ratingIndex >= 5 - props.targetLevel) ? ((ratingIndex + 1) * 44 + 7) : ((ratingIndex + 1) * 44)}
                                        fill="none" isAnimationActive={false} startAngle={135} endAngle={45} stroke="none" strokeWidth="1">
                                        <Label content={<g><image x={470} y={(ratingIndex >= 5 - props.targetLevel) ? 350 - (ratingIndex + 1) * 44 : 355 - (ratingIndex + 1) * 44}
                                            width="100" height="25" transform="translate(-20, 3)"
                                            href={"/assets/images/reportLevels/" + d[ratingIndex + 1] + "-b.png"} /></g>} />
                                    </Pie>
                                )
                            })}
                        </PieChart>
                    </div>
                    <div id="report-target-reached"> {/* target reached/not reached outer portion */}
                        <PieChart width={width} height={height}>
                            <Pie key="target-reached" data={recData} dataKey="value" cx="50%" cy="50%"
                                innerRadius={(5 * 44 + 7)} outerRadius={(5 * 44 + 27)} startAngle={90} endAngle={-270} isAnimationActive={false}>
                                {recData.map((item, index) => (
                                    <Cell key={index} fill={recData[index].recommendation === "" ? "#BAE6B0" : "#FFC5C7"} />
                                ))}
                            </Pie>
                        </PieChart>
                    </div>
                    <div id="report-target-reached-label"> {/* target reached/not reached outer text and positioning */}
                        <PieChart width={width} height={height}>
                            {recData.map((item, index) => (
                                <Pie key={index} data={emptyTargetData} dataKey="value" cx="50%" cy="50%"
                                    innerRadius={(5 * 44 + 7)} outerRadius={(5 * 44 + 27)}
                                    startAngle={((props.pieNumber - index) * 360 / props.pieNumber) - 270}
                                    endAngle={((props.pieNumber - (index + 1)) * 360 / props.pieNumber) - 270}
                                    isAnimationActive={false} fill="none">
                                    <Label value={recData[index].recommendation === "" ? "Target reached" : "Target not reached"}
                                        offset={recData[index].recommendation === "" ? (120 / props.pieNumber - 5) : (120 / props.pieNumber - 7)}
                                        position="insideStart" style={recData[index].recommendation === "" ? { fontSize: '80%', fill: "#5E9C3B" } : { fontSize: '80%', fill: "#BE4949" }} />
                                </Pie>
                            ))}
                        </PieChart>
                    </div>
                    <div id="report-rec"> {/* recommendation item with button */}
                        <PieChart width={width} height={height}>
                            <Pie data={recData} dataKey="value" cx="50%" cy="50%" startAngle={90} endAngle={-270}
                                innerRadius={(5 * 44 + 27)} outerRadius={(6 + 1) * 44} fill="none" isAnimationActive={false} label={renderCustomizedLabel} />
                        </PieChart>
                    </div>
                    <div id="legend"> {/* legend image */}
                        <img src="/assets/images/legend.png" alt="legend" />
                    </div>
                    {<Modal show={showModal} onHide={handleClose}> {/* modal/popup for 'how to reach next level' button */}
                        <Modal.Header>
                            <div>
                                {props.pageText["window.gap.title"]}
                                <h2>{recData[recIndex].title}</h2>
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <b>{props.pageText["label.recommendation"]}</b><br />
                                {recData[recIndex].recommendation}
                                <br />
                                <br />
                                <b>{props.pageText["label.outcome"]}</b><br />
                                {recData[recIndex].outcome}
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-subtle" onClick={handleClose}>
                                {props.pageText["button.close"]}
                            </button>
                        </Modal.Footer>
                    </Modal>}
                </div>}
            </div>
        )
    }

    return (
        <div>
            Something went wrong.
        </div>
    )
}

export default ChartComponent;