import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AuthenticatedApp from './Components/AuthenticatedApp';
import UnauthenticatedApp from './Components/UnauthenticatedApp';
import { AuthConsumer } from './Context/AuthContext';
import Loader from 'react-loader-spinner';

function App() {
  const [loading, setLoading] = useState(true);
  const [pageText, setPageText] = useState();
  useEffect(() => {
    axios.post('/api/v1.0/Config/GetPageText',
        {
          "app.name": "",
          "button.next.page": "",
          "button.next.question": "",
          "button.previous.question": "",
          "button.gap": "",
          "button.close": "",
          "window.gap.title": "",
          "label.recommendation": "",
          "label.outcome": "",
          "link.back": ""
        }
      ).then(function (response) {
        setPageText(response.data);
        setLoading(false);
      })
  }, [])

  return (
  <div>
    { loading ? 
    <Loader className="loader" type="Oval" color="#0F204B" height={100} width={100} /> :
    <AuthConsumer>
      {({ isSignedIn }) => (
        (isSignedIn === true) ? <AuthenticatedApp isSignedIn={isSignedIn} pageText={pageText} /> : 
        (isSignedIn === false) ? <UnauthenticatedApp /> :
        null
      )}
    </AuthConsumer>
    }
  </div>
  )
}

export default App;